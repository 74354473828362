var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"aside-log-camera-content",style:(_vm.logListv2.length ? 'height: 100%' : 'height: auto')},[_c('v-autocomplete',{staticClass:"aside-log-camera-searchbar",attrs:{"append-icon":"","clear-icon":"cancel","clearable":"","dense":"","filled":"","hide-details":"","items":_vm.filteredCameraNamesList,"background-color":"#f9f9f9","color":"#3D51FB","prepend-inner-icon":"search","placeholder":"Camera Name","height":"41px"},on:{"change":_vm.onChangeSearchbar,"focus":_vm.onFocus,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-tile-content',{staticClass:"autocomplete-item"},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.searchResult),callback:function ($$v) {_vm.searchResult=$$v},expression:"searchResult"}}),(!_vm.logListv2.length)?_c('div',{staticClass:"log-item-empty"},[_vm._v("No Data")]):_c('div',{staticClass:"log-accordian-content"},[_c('div',{staticClass:"wrapper"},_vm._l((_vm.filteredCameraList),function(camera){return _c('div',{key:camera.id},[_c('div',{class:{ 'camera-accordion-header': true, active: _vm.selectedCameraId === camera.id },on:{"click":function($event){return _vm.onClickCameraAccordion(camera)}}},[_c('div',{class:{ 'camera-accordion-text': true }},[_vm._v(_vm._s(camera.name))]),_c('div',{staticClass:"spacer-1"}),(_vm.selectedCameraId === camera.id && !_vm.isCameraLogsLoading)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-up-sub-black.svg")}}):(_vm.selectedCameraId === camera.id && _vm.isCameraLogsLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#363636","size":"16","width":"1"}}):_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-down-sub-black.svg")}})],1),(_vm.selectedCameraId === camera.id && _vm.isCameraLogsLoading)?_c('div',{staticClass:"camera-logs-loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#D8D9DA"}})],1):_vm._e(),(_vm.selectedCameraId === camera.id && !_vm.isCameraLogsLoading)?_c('div',{staticClass:"wrapper"},_vm._l((_vm.selectedCameraLogList(camera)),function(log){return _c('div',{key:log.log_id},[_c('div',{staticClass:"text sub",class:{
                  focus: _vm.selectId === log.log_id,
                  'top-bordered': _vm.index > 0,
                },on:{"click":function($event){return _vm.onClickLog(log)}}},[_c('v-tooltip',{attrs:{"bottom":"","color":"#F9F9F9","content-class":"aside-log-camera-tooltip","open-on-hover":"","open-delay":"2000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{
                        'log-text': true,
                        'text-bold': _vm.isBoldText(log),
                      }},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.displayLogText(log))+" ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"#363636"}},[_vm._v(_vm._s(_vm.camMap[log.camera_id].name))])]),_c('div',{staticClass:"spacer-1"}),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(_vm.isCheckedByOrganization(log))?_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"8px"},attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require('@/assets/Info_Line_16px_Icon_Light.svg'),"height":"14","width":"14"}})]):_vm._e()]}}],null,true)},[_c('div',{staticClass:"info-hover"},[_vm._v(_vm._s(_vm.getInfoHoverText(log)))])]),_c('div',{class:{
                    'icon-label': true,
                    fire: log.event_type_id === _vm.eventTypeList[2].id,
                    'non-fire': log.event_type_id === _vm.eventTypeList[3].id,
                    unknown: log.event_type_id === _vm.eventTypeList[4].id,
                    planned: log.event_type_id === _vm.eventTypeList[5].id,
                  }})],1)])}),0):_vm._e()])}),0)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }