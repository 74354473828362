<template>
  <div class="toast-content-container">
    <div class="toast-content">
      <div class="toast-title">New Log Detected</div>
      <div class="toast-text">해당 로그 확인하기</div>
    </div>
    <v-btn class="toast-check-btn" color="#FF5B54" depressed @click="onClickView()">View</v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    onClickView() {
      this.$emit('view-toast')
    },
  },
}
</script>

<style lang="scss" scoped>
.toast-content-container {
  display: flex;
  flex-grow: 1;

  .toast-content {
    display: flex;
    width: calc(100% - 70px);
    flex-direction: column;
    justify-content: center;

    .toast-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 5px;
    }

    .toast-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .toast-check-btn {
    height: 28px !important;
    width: 70px;
    position: fixed;
    bottom: 12px;
    right: 16px;
    font-weight: 700;
  }
}
</style>
