var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"log-accordian-content"},[_c('div',{staticClass:"wrapper"},_vm._l((_vm.sortedLogList),function(log,index){return _c('div',{key:log.log_id},[_c('div',{staticClass:"text sub",class:{
            focus: _vm.selectId === log.log_id,
            'top-bordered': index > 0,
          },on:{"click":function($event){return _vm.onClickLog(log)}}},[_c('v-tooltip',{attrs:{"bottom":"","color":"#F9F9F9","content-class":"aside-log-date-tooltip","open-on-hover":"","open-delay":"2000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{
                  'log-text': true,
                  'text-bold': _vm.isBoldText(log),
                }},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.displayLogText(log))+" ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"#363636"}},[_vm._v(_vm._s(_vm.camMap[log.camera_id].name))])]),_c('div',{staticClass:"spacer-1"}),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.isCheckedByOrganization(log))?_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"8px"},attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require('@/assets/Info_Line_16px_Icon_Light.svg'),"height":"14","width":"14"}})]):_vm._e()]}}],null,true)},[_c('div',{staticClass:"info-hover"},[_vm._v(_vm._s(_vm.getInfoHoverText(log)))])]),_c('div',{class:{
              'icon-label': true,
              fire: log.event_type_id === _vm.eventTypeList[2].id,
              'non-fire': log.event_type_id === _vm.eventTypeList[3].id,
              unknown: log.event_type_id === _vm.eventTypeList[4].id,
              planned: log.event_type_id === _vm.eventTypeList[5].id,
            }})],1)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }