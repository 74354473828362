import { render, staticRenderFns } from "./CaseFilterDropdown.vue?vue&type=template&id=01c40882&scoped=true&"
import script from "./CaseFilterDropdown.vue?vue&type=script&lang=js&"
export * from "./CaseFilterDropdown.vue?vue&type=script&lang=js&"
import style0 from "./CaseFilterDropdown.vue?vue&type=style&index=0&id=01c40882&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01c40882",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VMenu,VSpacer})
