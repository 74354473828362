<template>
  <div @click="onClickPaly()" :class="{ 'toolbar-button': true, disabled: disabled }">
    <!-- play -->
    <div v-if="isPlay">
      <img v-if="!disabled" src="@/assets/play.svg" class="icon" />
      <img v-else src="@/assets/play_disabled.svg" class="icon" />
      <span>Play</span>
    </div>
    <!-- resume -->
    <div v-if="isResume">
      <img src="@/assets/play.svg" class="icon" />
      <span>Resume</span>
    </div>
    <!-- playback -->
    <div v-if="isPlayback">
      <img src="@/assets/playback.svg" class="icon" />
      <span>Playback</span>
    </div>
    <!-- pause -->
    <div v-if="isPause">
      <img src="@/assets/pause.svg" class="icon" />
      <span>Pause</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    didPlay: Boolean,
    playing: Boolean,
    play: String,
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isPlay() {
      return this.loading || (!this.didPlay && !this.playing && this.play !== 'play')
    },
    isResume() {
      return !this.didPlay && this.playing && this.play !== 'play'
    },
    isPlayback() {
      return this.didPlay && this.play !== 'play'
    },
    isPause() {
      return !this.loading && this.play === 'play'
    },
  },
  methods: {
    onClickPaly() {
      this.$log.debug('ButtonPlay#onClickPlay', this.disabled)
      if (!this.disabled) {
        this.$emit('play')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.toolbar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 1;
  width: 120px;
  height: 34px;
  background-color: #3d51fb;
  border-radius: 30px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffffde;

  &.disabled {
    background-color: #0000001f;
    color: #00000042;
  }
}

.icon {
  margin-right: 10px;
}
</style>
