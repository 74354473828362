<template>
  <div>
    <div class="aside-log-camera-content" :style="logListv2.length ? 'height: 100%' : 'height: auto'">
      <v-autocomplete
        v-model="searchResult"
        append-icon=""
        class="aside-log-camera-searchbar"
        clear-icon="cancel"
        clearable
        dense
        filled
        hide-details
        :items="filteredCameraNamesList"
        background-color="#f9f9f9"
        color="#3D51FB"
        prepend-inner-icon="search"
        placeholder="Camera Name"
        @change="onChangeSearchbar"
        @focus="onFocus"
        @blur="onBlur"
        height="41px"
      >
        <template v-slot:item="{ item }">
          <v-list-tile-content class="autocomplete-item">
            {{ item }}
          </v-list-tile-content>
        </template>
      </v-autocomplete>
      <div v-if="!logListv2.length" class="log-item-empty">No Data</div>

      <div v-else class="log-accordian-content">
        <div class="wrapper">
          <div v-for="camera in filteredCameraList" :key="camera.id">
            <div @click="onClickCameraAccordion(camera)" :class="{ 'camera-accordion-header': true, active: selectedCameraId === camera.id }">
              <div :class="{ 'camera-accordion-text': true }">{{ camera.name }}</div>
              <div class="spacer-1"></div>
              <img v-if="selectedCameraId === camera.id && !isCameraLogsLoading" src="@/assets/Icon ionic-ios-arrow-up-sub-black.svg" />
              <v-progress-circular
                v-else-if="selectedCameraId === camera.id && isCameraLogsLoading"
                indeterminate
                color="#363636"
                size="16"
                width="1"
              ></v-progress-circular>
              <img v-else src="@/assets/Icon ionic-ios-arrow-down-sub-black.svg" />
            </div>

            <!-- Loading -->
            <div v-if="selectedCameraId === camera.id && isCameraLogsLoading" class="camera-logs-loading">
              <v-progress-circular indeterminate color="#D8D9DA"></v-progress-circular>
            </div>
            <!-- Logs -->
            <div class="wrapper" v-if="selectedCameraId === camera.id && !isCameraLogsLoading">
              <div v-for="log in selectedCameraLogList(camera)" :key="log.log_id">
                <div
                  class="text sub"
                  :class="{
                    focus: selectId === log.log_id,
                    'top-bordered': index > 0,
                  }"
                  @click="onClickLog(log)"
                >
                  <v-tooltip bottom color="#F9F9F9" content-class="aside-log-camera-tooltip" open-on-hover open-delay="2000">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        :class="{
                          'log-text': true,
                          'text-bold': isBoldText(log),
                        }"
                      >
                        {{ displayLogText(log) }}
                      </div>
                    </template>
                    <span style="color: #363636">{{ camMap[log.camera_id].name }}</span>
                  </v-tooltip>
                  <div class="spacer-1"></div>
                  <v-menu open-on-hover offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="isCheckedByOrganization(log)" x-small icon v-bind="attrs" v-on="on" style="margin-right: 8px">
                        <img :src="require('@/assets/Info_Line_16px_Icon_Light.svg')" height="14" width="14" />
                      </v-btn>
                    </template>
                    <div class="info-hover">{{ getInfoHoverText(log) }}</div>
                  </v-menu>
                  <div
                    :class="{
                      'icon-label': true,
                      fire: log.event_type_id === eventTypeList[2].id,
                      'non-fire': log.event_type_id === eventTypeList[3].id,
                      unknown: log.event_type_id === eventTypeList[4].id,
                      planned: log.event_type_id === eventTypeList[5].id,
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import Util from '@/util'

export default {
  props: {
    selectId: Number,
    tabIndex: Number,
    /**
     * emit events
     * click-log Object
     */
  },
  data() {
    return {
      searchResult: '',
      selectedCameraId: -1,
      isCameraLogsLoading: false,
      cameraLoadingTimeout: null,
      searchBarFocus: false,
    }
  },
  computed: {
    ...mapState(['camList', 'camMap', 'eventTypeList', 'camList', 'user', 'workspaceCameraLogsMap', 'logListv2']),
    datePeriod() {
      return this.$store.state.superadmin.datePeriod
    },
    filteredCameraNamesList() {
      const logListCamerasIdList = [...new Set(this.logListv2.map((log) => log.camera_id))]
      return this.camList.filter((camera) => logListCamerasIdList.includes(camera.id)).map((cam) => cam.name)
    },
    filteredCameraList() {
      if (this.searchResult) {
        return this.camList.filter((cam) => cam.name.includes(this.searchResult))
      }
      const logListCamerasIdList = [...new Set(this.logListv2.map((log) => log.camera_id))]
      return this.camList.filter((camera) => logListCamerasIdList.includes(camera.id))
    },
  },
  methods: {
    ...mapActions(['getLogListv2ByCameraId']),
    onFocus() {
      this.searchBarFocus = true
    },
    onBlur() {
      this.searchBarFocus = false
    },
    onChangeSearchbar() {
      if (this.selectedCameraId !== -1) {
        this.selectedCameraId = -1
      }
    },
    async onClickCameraAccordion(camera) {
      this.isCameraLogsLoading = true
      if (this.selectedCameraId === camera.id) {
        this.selectedCameraId = -1
      } else {
        this.selectedCameraId = camera.id
        const startDate = moment(this.datePeriod.startDate, 'YYYY-MM-DD').startOf('day').format()
        const endDate = moment(this.datePeriod.endDate, 'YYYY-MM-DD').endOf('day').format()
        const workerIds = this.$store.state.superadmin.workerIdList
        await this.getLogListv2ByCameraId({
          startDate,
          endDate,
          tabIndex: this.tabIndex,
          workerIds,
          cameraId: camera.id,
          inChild: this.tabIndex === 1,
          inRepOrg: this.tabIndex === 1,
        }).then((result) => {
          if (!!result) {
            this.isCameraLogsLoading = false
          } else {
            this.cameraLoadingTimeout = setTimeout(() => {
              this.isCameraLogsLoading = false
            }, 200)
          }
        })
      }
    },
    selectedCameraLogList(camera) {
      return this.workspaceCameraLogsMap[camera.id]
    },
    isBoldText(log) {
      return this.tabIndex === 1 && !Util.isDuplicateLog(log)
    },
    displayLogText(log) {
      return '[' + Util.getOffsetDate(log.created_at, log.offset) + '] ' + this.camMap[log.camera_id].name
    },
    onClickLog(log) {
      if (log.log_id !== this.selectId) {
        this.$emit('click-log', log)
      }
    },
    getInfoHoverText(log) {
      return Util.isDuplicateLog(log) ? 'First Detection to Repetitive' : 'Repetitive to First Detection'
    },
    isCheckedByOrganization(log) {
      return log.is_checked_organization
    },
  },
  destroyed() {
    clearTimeout(this.cameraLoadingTimeout)
  },
}
</script>

<style lang="scss" scoped>
.aside-log-camera-content {
  display: flex;
  flex-direction: column;
  width: 444px;
  padding: 15px 0px 0px 30px;
  // padding: 0 10px;
  // max-height: 402px;
  flex-grow: 1;
  // overflow: hidden;

  .aside-log-camera-searchbar {
    border-radius: 8px;
    margin-bottom: 10px;
    min-width: 415px;
  }
}

.log-accordian-content {
  display: flex;
  flex-direction: column;
  // max-height: 402px;
  height: 100%;
  width: 415px;
  overflow: auto;

  .wrapper {
    overflow: auto;

    .camera-accordion-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      border-radius: 15px;
      height: 60px;
      flex: 0 0 67px;
      cursor: pointer;
      background-color: #f9f9f9;
      // background-color: var(--v-backgroundAsideHeader-base);
      padding: 0px 30px 0px 20px;

      &.active {
        // background-color: #F9F9F9;
        background-color: var(--v-backgroundAsideHeader-base);
      }

      .camera-accordion-text {
        font-size: 16px;
        font-weight: 500;
        color: #363636;
        line-height: 19px;
      }
    }
  }

  .text.sub {
    height: 30px;
    display: flex;
    font-weight: 400;
    align-items: center;
    font-size: 15px;
    color: var(--v-textDefault-white);
    padding-left: 30px;
    padding-right: 18px;
    cursor: pointer;

    &.focus {
      // background-color: #e9ebff;
      color: #3d51fb;
    }

    &.top-bordered {
      border-top: 1px solid #d3d5dd;
    }

    .log-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      user-select: text;
    }
    .text-bold {
      font-weight: 600;
    }
  }
}

.info-hover {
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 15px;
  background-color: #f9f9f9;
  font-size: 12px;
  color: #363636;
  white-space: nowrap;
}

.icon-label {
  min-width: 13px;
  max-width: 13px;
  height: 13px;
  border-radius: 13px;
  border: 1px solid var(--v-backgroundUnselected);

  &.fire {
    background-color: #ff5b54;
    border-width: 0px;
  }

  &.non-fire {
    background-color: #64d280;
    border-width: 0px;
  }

  &.unknown {
    background-color: #61a0ff;
    border-width: 0px;
  }

  &.planned {
    background-color: #7b61ff;
    border-width: 0px;
  }
}

.datepicker {
  position: absolute;
  top: 292px;
  left: -345px;
}

.camera-logs-loading {
  height: 70px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.log-item-empty {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 15px;
  // margin: 15px 20px 0px 10px;
  font-weight: 500;
  font-size: 16px;
  color: #afb6c3;
}

.aside-log-camera-tooltip {
  opacity: 1 !important;
}
</style>
