<template>
  <div @click="$emit('click')" class="d-flex align-center" style="z-index: 1; cursor: pointer; font-weight: 500; font-size: 16px">
    <img v-if="$vuetify.theme.dark" src="@/assets/Goto_Icon.svg" style="margin-right: 7px" />
    <img v-else src="@/assets/Goto_Blue_Icon.svg" style="margin-right: 7px" />
    <span style="color: var(--v-textAside)">Go to</span>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
