<template>
  <button class="xsmall-button" @click="clickButton" v-ripple :class="{ dark: $vuetify.theme.dark }" :style="{ backgroundColor }">
    {{ content }}
  </button>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: 'button',
    },
    backgroundColor: {
      type: String,
      default: '#f9423a',
    },
  },
  methods: {
    clickButton() {
      this.$emit('clickMethod')
    },
  },
}
</script>

<style scoped>
.xsmall-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  width: 80px;
  height: 30px;
  border-radius: 8px;
  color: var(--f-text-white);
}
</style>
