<template>
  <div style="display: flex">
    <img class="checkbox" v-if="disable" @click="turnOnDropdown" src="@/assets/Checkbox-Off_16px_Icon_light.svg" width="16px" />
    <img class="checkbox" v-else-if="open || selected" @click.stop="setToDefaultState" src="@/assets/Checkbox-On_16px_blue_Icon.svg" width="16px" />
    <img class="checkbox" v-else @click="turnOffDropdown" src="@/assets/Checkbox-On_16px_1_Icon.svg" width="16px" />
    <v-menu v-model="open" offset-y nudge-bottom="5px" :close-on-content-click="false" :disabled="disable">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" :class="{ 'dropdown-display': true, open, select: selected, disable }" @click="resetSelect">
          <div class="text-truncate">{{ placeholder }}</div>
          <v-spacer></v-spacer>
          <img v-if="!open && selected" @click.stop="onClear" src="@/assets/Input_Search_Bar_Close-blue.svg" width="16px" />
          <img v-else-if="open" src="@/assets/icon-arrow-up-fill.svg" width="10px" />
          <img v-else-if="disable" src="@/assets/icon-arrow-down-grey.svg" width="10px" />
          <img v-else src="@/assets/icon-arrow-down-black.svg" width="10px" />
        </div>
      </template>
      <div class="dropdown-container">
        <div @click="onClickSelectAll()" class="select-all-container">
          <img v-if="selectAll" src="@/assets/Checkbox-On_16px_1_Icon.svg" width="16px" />
          <img v-else src="@/assets/Checkbox_Unchecked_Grey_Icon.svg" width="16px" />
          <div>Select All</div>
        </div>
        <div class="wrapper">
          <div v-for="item in items" :key="item.id" @click="onClickItem(item.id)" :class="{ item: true, select: selectMap.get(item.id) }">
            <img v-if="selectMap.get(item.id)" src="@/assets/Checkbox-On_16px_1_Icon.svg" />
            <img v-else src="@/assets/Checkbox_Unchecked_Grey_Icon.svg" />
            <div class="text-truncate">{{ item.name }}</div>
          </div>
        </div>
        <div class="button-container">
          <XsmallButton :content="'Save'" :backgroundColor="'#3D51FB'" @clickMethod="onClickSave" />
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import XsmallButton from '@item/button/XsmallButton'
export default {
  components: { XsmallButton },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    isSuperadmin: {
      type: Boolean,
      default: false,
    },
    workerListUpdate: {
      type: Boolean,
      default: false,
    },
    workerIdsList: {
      type: Object,
      default: [],
    },
  },
  data() {
    return {
      open: false,
      search: '',
      searchFocus: false,
      selected: false,
      selectMap: new Map(),
      selectAll: false,
      dropdownStyle: '',
      disable: true,
    }
  },
  created() {
    this.disable = !this.workerListUpdate
    this.selected = this.workerListUpdate
    const defaultMap = new Map()
    this.workerIdsList.forEach((id) => {
      defaultMap.set(id, true)
    })
    this.selectMap = defaultMap
  },
  methods: {
    resetSelect() {
      if (!this.disable && !this.open && !this.selected) {
        this.setDefaultSelect()
      }
    },
    turnOnDropdown() {
      this.disable = false
      this.setDefaultSelect()
    },
    setDefaultSelect() {
      if (this.isSuperadmin) this.onClickSelectAll(true, true)
      else {
        const defaultMap = new Map()
        defaultMap.set(this.items[0].id, true)
        this.selectMap = defaultMap
        this.selectAll = false
      }
    },
    turnOffDropdown() {
      this.disable = true
    },
    onClickItem(index) {
      this.selectMap.set(index, !this.selectMap.get(index))
      this.selectMap = new Map(this.selectMap)
      if (this.selectMap.size === this.items.length) {
        let selectAll = true
        const values = this.selectMap.values()
        for (let v of values) {
          if (!v) {
            selectAll = false
            break
          }
        }
        this.selectAll = selectAll
      }
    },
    onClickSelectAll(forceValue = false, selectAll = false) {
      if (forceValue) {
        this.selectAll = selectAll
      } else {
        this.selectAll = !this.selectAll
      }
      this.selectMap = new Map()
      if (this.selectAll) {
        for (let i = 0; i < this.items.length; i++) {
          this.selectMap.set(this.items[i].id, true)
        }
      }
    },
    onClickSave() {
      let indexes = []
      const entries = this.selectMap.entries()
      for (let v of entries) {
        if (v[1]) {
          indexes.push(v[0])
        }
      }
      this.$emit('save', indexes)
      this.open = false
      if (!indexes.length) {
        this.selected = false
        this.disable = true
      } else this.selected = true
    },
    onClear() {
      this.setDefaultSelect()
      this.selected = false
      let indexes = []
      const entries = this.selectMap.entries()
      for (let v of entries) {
        if (v[1]) {
          indexes.push(v[0])
        }
      }
      this.$emit('save', indexes)
    },
    setToDefaultState() {
      this.onClear()
      this.turnOffDropdown()
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-display {
  font-size: 14px;
  color: var(--f-text-black);
  position: relative;
  border-radius: 8px;
  background-color: var(--f-light-gray-1);
  display: flex;
  width: 100%;
  height: 36px;
  padding: 0 16px 0 16px;
  align-items: center;
  &.open {
    border: 1px solid var(--v-textAside);
    color: var(--v-textAside);
  }
  &.disable {
    background-color: var(--f-text-black-low);
    color: var(--f-text-black-middle);
  }
  &.select {
    border: 1px solid var(--v-textAside);
    background-color: var(--v-backgroundSearchMask);
    color: var(--v-textAside);
  }
}

.dropdown-container {
  background: #f9f9f9;
  box-shadow: 5px 5px 10px rgba(61, 81, 251, 0.1);
  border-radius: 10px;
  overflow: auto;
  overflow-x: hidden;
  font-size: 14px;
  font-weight: 400;
  color: #363636;

  .search-container {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 29px;
    border-bottom: 1px solid #d8d9da;
    border-radius: 10px 10px 0px 0px;

    &.focus {
      border: 1px solid #3d51fb;
    }

    input {
      outline: none;
      caret-color: #3d51fb;

      &::placeholder {
        font-size: 12px;
        color: #363636;
      }
    }
  }

  .select-all-container {
    display: flex;
    align-items: center;
    padding-left: 16px;
    height: 37px;
    border-bottom: 1px solid #d8d9da;
    cursor: pointer;

    div {
      margin-left: 8px;
    }
  }

  .wrapper {
    max-height: 181px;
    overflow: auto;
    border-bottom: 1px solid #d8d9da;
  }

  .item {
    display: flex;
    align-items: center;
    height: 36px;
    padding-left: 16px;
    width: 100%;
    color: #363636;
    cursor: pointer;

    &.select {
      // background: #F9F9F9;
    }

    &:not(:last-child) {
      // border-bottom: 1px solid #f9f9f9;
    }

    img {
      width: 16px;
      min-width: 16px;
      margin-right: 5px;
    }

    .search {
      color: white;
      background: #6d7dff;
    }
  }

  .button-container {
    display: flex;
    padding: 8px 0 8px 0;
    justify-content: center;
  }
}

.checkbox {
  margin-right: 8px;
  cursor: pointer;
}
</style>
