<template>
  <div>
    <div class="log-accordian-content">
      <div class="wrapper">
        <div v-for="(log, index) in sortedLogList" :key="log.log_id">
          <div
            class="text sub"
            :class="{
              focus: selectId === log.log_id,
              'top-bordered': index > 0,
            }"
            @click="onClickLog(log)"
          >
            <v-tooltip bottom color="#F9F9F9" content-class="aside-log-date-tooltip" open-on-hover open-delay="2000">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  :class="{
                    'log-text': true,
                    'text-bold': isBoldText(log),
                  }"
                >
                  {{ displayLogText(log) }}
                </div>
              </template>
              <span style="color: #363636">{{ camMap[log.camera_id].name }}</span>
            </v-tooltip>
            <div class="spacer-1"></div>
            <v-menu open-on-hover offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="isCheckedByOrganization(log)" x-small icon v-bind="attrs" v-on="on" style="margin-right: 8px">
                  <img :src="require('@/assets/Info_Line_16px_Icon_Light.svg')" height="14" width="14" />
                </v-btn>
              </template>
              <div class="info-hover">{{ getInfoHoverText(log) }}</div>
            </v-menu>
            <div
              :class="{
                'icon-label': true,
                fire: log.event_type_id === eventTypeList[2].id,
                'non-fire': log.event_type_id === eventTypeList[3].id,
                unknown: log.event_type_id === eventTypeList[4].id,
                planned: log.event_type_id === eventTypeList[5].id,
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Util from '@/util'

export default {
  props: {
    selectId: Number,
    tabIndex: Number,
    /**
     * emit events
     * click-log Object
     */
  },
  data() {
    return {
      selectedDate: '',
      loading: false,
    }
  },
  computed: {
    ...mapState(['camMap', 'eventTypeList', 'camList', 'user', 'logListv2']),
    sortedLogList() {
      return this.logListv2.sort((a, b) => a.created_at > b.created_at)
    },
  },
  methods: {
    isBoldText(log) {
      return this.tabIndex === 1 && !Util.isDuplicateLog(log)
    },
    isCheckedByOrganization(log) {
      return log.is_checked_organization
    },
    displayLogText(log) {
      return '[' + Util.getOffsetDate(log.created_at, log.offset) + '] ' + this.camMap[log.camera_id].name
    },
    onClickLog(log) {
      if (log.log_id !== this.selectId) {
        this.$emit('click-log', log)
      }
    },
    getInfoHoverText(log) {
      return Util.isDuplicateLog(log) ? 'First Detection to Repetitive' : 'Repetitive to First Detection'
    },
  },
}
</script>

<style lang="scss" scoped>
.log-accordian-content {
  width: 435px;
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: var(--v-backgroundAsideHeaderRecentBorder-base);
  margin: 10px 16px 0px 20px;
  border-radius: 15px;
  max-height: 402px;
  overflow: hidden;

  .wrapper {
    overflow: auto;
  }

  .text.sub {
    height: 50px;
    display: flex;
    font-weight: 400;
    align-items: center;
    font-size: 15px;
    color: var(--v-textDefault-white);
    padding-left: 30px;
    padding-right: 20px;
    cursor: pointer;

    &.focus {
      background-color: #e9ebff;
      // color: #3d51fb;
    }

    &.top-bordered {
      border-top: 1px solid #d3d5dd;
    }

    .log-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      user-select: text;
    }
    .text-bold {
      font-weight: 600;
    }
  }
}

.info-hover {
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 15px;
  background-color: #f9f9f9;
  font-size: 12px;
  color: #363636;
  white-space: nowrap;
}

.icon-label {
  min-width: 13px;
  max-width: 13px;
  height: 13px;
  border-radius: 13px;
  border: 1px solid var(--v-backgroundUnselected);

  &.fire {
    background-color: #ff5b54;
    border-width: 0px;
  }

  &.non-fire {
    background-color: #64d280;
    border-width: 0px;
  }

  &.unknown {
    background-color: #61a0ff;
    border-width: 0px;
  }

  &.planned {
    background-color: #7b61ff;
    border-width: 0px;
  }
}

.datepicker {
  position: absolute;
  top: 292px;
  left: -345px;
}

.aside-log-date-tooltip {
  opacity: 1 !important;
}
</style>
