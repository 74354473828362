<template>
  <aside :class="{ background: true, worker: isWorker }">
    <div :class="{ 'content-wrapper': true, worker: user.isWorker }" :style="asideMarginBottom">
      <!-- case dropdown -->
      <div class="dropdown-wrapper">
        <CaseFilterDropdown
          :items="workerList"
          :placeholder="'Show Other Cases'"
          @save="updateShowUserList"
          :isSuperadmin="isSuperadmin"
          :workerListUpdate="workerListUpdate"
          :workerIdsList="workerIdsList"
        />
        <button class="reset-calendar" @click="resetCalendarRange">Recent</button>
      </div>
      <!-- date range selection -->
      <AsideLogCalendar />
      <!-- Date Header -->
      <div key="date" @click="onClickDate" :class="{ 'log-accordion-header': true, active: isOpenDate }">
        <img v-if="$vuetify.theme.dark || (!$vuetify.theme.dark && isOpenDate)" src="@/assets/Icon ionic-ios-time-dark.svg" class="img-recent" />
        <img v-if="!$vuetify.theme.dark && !isOpenDate" src="@/assets/Icon ionic-ios-time-light.svg" class="img-recent" />
        <div :class="{ 'text-log-header': true, active: isOpenDate }" style="margin-left: 15px">Date</div>
        <div class="spacer-1"></div>
        <img v-if="isOpenDate" src="@/assets/Icon ionic-ios-arrow-up.svg" class="img-arrow" />
        <img v-if="$vuetify.theme.dark && !isOpenDate" src="@/assets/Icon ionic-ios-arrow-down-dark.svg" class="img-arrow" />
        <img v-if="!$vuetify.theme.dark && !isOpenDate" src="@/assets/Icon ionic-ios-arrow-down-light.svg" class="img-arrow" />
      </div>

      <!-- Date Content -->
      <div v-if="isOpenDate && isDateLogsLoading" class="log-list-loading">
        <v-progress-circular indeterminate color="#D8D9DA"></v-progress-circular>
      </div>
      <div v-else-if="isOpenDate && !isDateLogsLoading && !logListv2.length" class="log-item-empty">No Data</div>
      <AsideLogDate
        v-else-if="isOpenDate && !isDateLogsLoading && logListv2.length"
        :selectId="selectId"
        :tabIndex="tabIndex"
        @click-log="onClickLog"
        @save-label="onClickSaveLabel"
      ></AsideLogDate>

      <!-- Camera Header -->
      <div key="camera" @click="onClickCamera" :class="{ 'log-accordion-header': true, active: isOpenCamera }">
        <img
          v-if="$vuetify.theme.dark || (!$vuetify.theme.dark && isOpenCamera)"
          src="@/assets/MonitoringSelectedCamera_29px.svg"
          class="img-recent"
          width="29"
        />
        <img v-if="!$vuetify.theme.dark && !isOpenCamera" src="@/assets/MonitoringUnselectedCamera_29px.svg" class="img-recent" width="29" />
        <div :class="{ 'text-log-header': true, active: isOpenCamera }" style="margin-left: 15px">Camera</div>
        <div class="spacer-1"></div>
        <img v-if="isOpenCamera" src="@/assets/Icon ionic-ios-arrow-up.svg" class="img-arrow" />
        <img v-if="$vuetify.theme.dark && !isOpenCamera" src="@/assets/Icon ionic-ios-arrow-down-dark.svg" class="img-arrow" />
        <img v-if="!$vuetify.theme.dark && !isOpenCamera" src="@/assets/Icon ionic-ios-arrow-down-light.svg" class="img-arrow" />
      </div>

      <!-- Camera Content -->
      <div v-if="isOpenCamera && isCameraListLoading" class="log-list-loading">
        <v-progress-circular indeterminate color="#D8D9DA"></v-progress-circular>
      </div>
      <AsideLogCamera
        v-if="isOpenCamera && !isCameraListLoading"
        style="height: calc(100% - 280px); overflow: hidden"
        :selectId="selectId"
        :tabIndex="tabIndex"
        @click-log="onClickLog"
        @save-label="onClickSaveLabel"
      ></AsideLogCamera>
    </div>
  </aside>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import { alert, workspace } from '@/api'
import Vue from 'vue'
import Util from '@/util'
import Constants from '@/constants'
import Calendar from '@components/common/Calendar'
import { POSITION } from 'vue-toastification'

import AsideLogDate from '@/components/desktop/superadminOrWorker/alert/AsideLogDate'
import AsideLogCamera from '@/components/desktop/superadminOrWorker/alert/AsideLogCamera'
import AsideLogCalendar from '@/components/desktop/superadminOrWorker/workspace/AsideLogCalendar'
import CaseFilterDropdown from '@desktop/superadminOrWorker/settings/CaseFilterDropdown'

import WorkspaceToastContent from '@/components/common/WorkspaceToastContent.vue'
import ToastCloseIconLight from '@/components/common/ToastCloseIconLight.vue'

export default {
  components: {
    Calendar,
    AsideLogDate,
    AsideLogCamera,
    AsideLogCalendar,
    CaseFilterDropdown,
    WorkspaceToastContent,
    ToastCloseIconLight,
  },
  props: {
    tabIndex: Number,
    currentCam: Object,
    currentLog: Object,
    isSiteSearchSelected: {
      type: Boolean,
      default: false,
    },
    /**
     * emit events
     * v-model Boolean
     * select-log Object
     * select-cam Object
     * select-tab String
     */
  },
  data() {
    const startDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')
    return {
      // workerList: {
      //   1: 'a',
      //   2: 'b',
      //   3: 'b',
      // },
      Constants,
      isOpenDetailedInfo: false,
      isOpenMap: false,
      isOpenRecent: false,
      isOpenDate: false,
      isDateLogsLoading: false,
      isOpenCamera: false,
      isCameraListLoading: false,
      isOpenSite: false,
      isOpenCam: false,
      selectSite: -1,
      selectId: -1,
      openDatepicker: false,
      dates: `${startDate} ~ ${endDate}`,
      currentCamImagePath: '',
      asideMarginBottom: 'margin-bottom:0px',
      degreeString: '',
    }
  },
  computed: {
    ...mapState(['recentLogList', 'logListv2', 'camMap', 'camList', 'eventTypeList', 'user']),
    workerListUpdate() {
      return this.$store.state.superadmin.workerListUpdate
    },
    workerList() {
      return this.$store.state.superadmin.workerList
    },
    workerIdsList() {
      return this.$store.state.superadmin.workerIdList
    },
    isSuperadmin() {
      return this.user.user_role.includes(Constants.ROLE_SUPER)
    },
    datePeriod() {
      return this.$store.state.superadmin.datePeriod
    },
    siteList() {
      const camList = this.camList
      if (this.currentCam && this.currentCam.id) {
        const searchIndex = camList.findIndex((cam) => cam.id === this.currentCam.id)
        if (searchIndex > -1) {
          this.selectId = -1
          return [...camList]
        }
      }
      return camList
    },
    calendarMinDate() {
      const history = moment(this.user.history_start)
      const days = moment().diff(history, 'days')
      return moment()
        .startOf('day')
        .subtract(days + 1, 'days')
    },
    userRole() {
      return this.user.user_role
    },
    isViewer() {
      return this.user.isViewer
    },
    isWorker() {
      return this.user.isWorker
    },
    selectedLog() {
      let log
      if (this.currentLog) {
        log = this.recentLogList.find((log) => log.log_id === this.currentLog.log_id)
        if (!log) {
          log = this.logListv2.find((log) => log.log_id === this.currentLog.log_id)
        }
      }
      return log
    },
    mapDisabled() {
      if (!this.isOpenCam) {
        return false
      } else if (!!this.selectedLog && (!this.selectedLog.direction || this.selectedLog.direction === -1)) {
        return false
      } else if (!!this.currentCam) {
        return false
      }
      return true
    },
    mapLoading() {
      if (this.selectedLog && this.selectedLog.direction === null && !this.isOpenCam) {
        return true
      }
      return false
    },
  },
  watch: {
    async tabIndex(newInd, oldInd) {
      // tab index of: [ Confirmed, Skipped, Skipped(Hard Case) ]
      if ([1, 2, 3].includes(newInd)) {
        if (newInd !== oldInd) {
          this.isOpenDate = false
          this.isDateLogsLoading = false
          this.isOpenCamera = false
          this.isCameraListLoading = false
        }
        this.refreshLogListv2()
      }
    },
    /**
     * open selected log card
     */
    currentLog(log) {
      if (log && log.log_id !== this.selectId) {
        // this.onClickLog(log, !this.isOpenSite)
        this.selectId = log.log_id
      } else if (!log) {
        this.selectSite = -1
        this.selectId = -1
      }
    },
    mapDisabled(val) {
      if (val) {
        this.isOpenMap = false
      }
    },
    mapLoading(val) {
      if (val) {
        this.isOpenMap = false
      }
    },
    datePeriod() {
      this.isOpenDate = false
      this.isOpenCamera = false
      this.refreshLogListv2()
    },
  },
  methods: {
    ...mapActions(['getLogListv2', 'setRecentLogListInWorkspace', 'getLog', 'emptyWorkspaceCameraLogsMap', 'setWorkspaceTabIndex']),
    resetCalendarRange() {
      const date = [moment().subtract(2, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      this.$store.dispatch('superadmin/setDatePeriodAction', date)
    },
    async updateShowUserList(userIdList) {
      // update list of user IDs
      this.$store.dispatch('superadmin/setWorkerListAction', userIdList)
      // update recent logs list
      const startDate = moment(this.datePeriod.startDate, 'YYYY-MM-DD').startOf('day').format()
      const endDate = moment(this.datePeriod.endDate, 'YYYY-MM-DD').endOf('day').format()
      await this.getLogListv2({ startDate, endDate, tabIndex: this.tabIndex, workerIds: this.workerIdsList })
      if (this.logListv2 && this.logListv2.length) {
        const firstLogID = this.logListv2[0].log_id
        await this.getLog(firstLogID).then((result) => {
          if (!!result) {
            if (this.isOpenDate) {
              this.isOpenDate = !this.isOpenDate
              this.isDateLogsLoading = false
            }
            if (this.isOpenCamera) {
              this.isOpenCamera = !this.isOpenCamera
              this.isCameraListLoading = false
            }
          }
        })
      } else {
        if (this.isOpenDate) {
          this.isOpenDate = !this.isOpenDate
          this.isDateLogsLoading = false
        }
        if (this.isOpenCamera) {
          this.isOpenCamera = !this.isOpenCamera
          this.isCameraListLoading = false
        }
      }
    },
    onClickOpen() {
      this.isOpenDetailedInfo = false
      this.isOpenMap = false
      this.isOpenRecent = false
      this.isOpenSite = false
      this.isOpenCam = false
    },
    onClickDetailedInfo() {
      // this.$log.debug('desktop#AsideLog#onClickDetailedInfo')
      this.openDatepicker = false
      this.isOpenDetailedInfo = !this.isOpenDetailedInfo
    },
    onClickMap() {
      // this.$log.debug('desktop#AsideLog#onClickMap mapLoading/mapDisabled', this.mapLoading, this.mapDisabled)
      if (!this.mapDisabled && !this.mapLoading) {
        this.openDatepicker = false
        this.isOpenMap = !this.isOpenMap
      }
    },
    onClickRecent() {
      this.selectSite = -1
      this.selectId = -1
      this.openDatepicker = false
      this.isOpenRecent = !this.isOpenRecent
      if (this.isOpenSite) {
        this.isOpenSite = !this.isOpenSite
      }
      if (this.isOpenCam) {
        this.isOpenCam = !this.isOpenCam
      }
      this.$emit('select-tab', this.isOpenRecent ? 'recent' : '')
      this.$emit('select-cam', null)
    },
    async onClickSite() {
      this.openDatepicker = false
      this.isOpenSite = !this.isOpenSite
      if (this.isOpenRecent) {
        this.isOpenRecent = !this.isOpenRecent
      }
      if (this.isOpenCam) {
        this.isOpenCam = !this.isOpenCam
      }
      this.$emit('select-tab', this.isOpenSite ? 'history' : '')
    },
    async onClickDate() {
      this.openDatepicker = false
      this.isDateLogsLoading = true
      if (this.isOpenCamera) {
        this.isOpenCamera = !this.isOpenCamera
      }
      if (this.isOpenRecent) {
        this.isOpenRecent = !this.isOpenRecent
      }
      if (this.isOpenCam) {
        this.isOpenCam = !this.isOpenCam
      }
      if (!this.isOpenCamera) {
        this.emptyWorkspaceCameraLogsMap()
      }
      // this.$emit('select-tab', this.isOpenDate ? 'date' : '')
      this.isOpenDate = !this.isOpenDate
      this.isDateLogsLoading = false
    },
    async onClickCamera() {
      this.openDatepicker = false
      this.isCameraListLoading = true
      if (this.isOpenDate) {
        this.isOpenDate = !this.isOpenDate
      }
      if (this.isOpenRecent) {
        this.isOpenRecent = !this.isOpenRecent
      }
      if (this.isOpenCam) {
        this.isOpenCam = !this.isOpenCam
      }
      if (!this.isOpenCamera) {
        this.emptyWorkspaceCameraLogsMap()
      }
      // this.$emit('select-tab', this.isOpenCamera ? 'date' : '')
      this.isOpenCamera = !this.isOpenCamera
      this.isCameraListLoading = false
    },
    onClickCalendarOutside() {
      this.openDatepicker = false
    },
    findFirstLog(siteId) {
      const site = this.siteList.find((site) => site.id === siteId)
      return site.logs[0]
    },
    onClickLog(log, noSelectId = false) {
      // aside쪽 점진적 개발에 따른 컬럼들간의 로직이 점점 복잡해지는 로직에 대해서 정리하고 단순화하도록할 refactoring이 필요함
      const selectedSite = log.camera_id || log.id
      // this.$log.debug('desktop#AsideLog#onClickLog', this.selectSite, selectedSite)
      if (log.log_id || selectedSite !== this.selectSite) {
        this.selectSite = log.camera_id || log.id
        if (log.log_id) {
          // this.$log.debug('desktop#AsideLog#onClickLog select-log')
          this.$emit('select-log', log)
        } else {
          // this.$log.debug('desktop#AsideLog#onClickLog select-log first of site', selectedSite)
          this.$emit('select-log', this.findFirstLog(selectedSite))
        }
        if (this.selectId == log.log_id || noSelectId) {
          // this.$log.debug('desktop#AsideLog#onClickLog selectId -1', noSelectId)
          if (!this.isOpenSite) {
            this.selectId = -1
            // !noSelectId && this.$emit('select-tab', 'recent')
          }
        } else {
          this.selectId = log.log_id
          // this.$log.debug('desktop#AsideLog#onClickLog selectId', this.selectId)
        }
        if (!this.mapLoading && !this.mapDisabled) {
          this.isOpenMap = true
        }
        if (this.selectSite) {
          // this.$log.debug('desktop#AsideLog#onClickLog selectSite', this.selectSite)
          const cam = this.camMap[this.selectSite]
          if (cam) {
            this.$emit('select-cam', cam)
            this.currentCamImagePath = Util.getImageUrl(cam.path, true)
          }
        }
      } else {
        // this.$log.debug('desktop#AsideLog#onClickLog null recent first', this.recentLogList && this.recentLogList[0])
        if (this.recentLogList && this.recentLogList[0]) {
          // 일단, sync가 안맞아서 어떻게 해야 할지 고민하다가 callback으로 처리하는 방법을 사용함... sync를 어떻게 맞춰야 할지 잘 모르겠음
          this.recentLogList[0].callback = () => {
            this.$emit('select-cam', null)
            this.selectSite = -1
            this.selectId = -1
          }
          this.$emit('select-log', this.recentLogList[0])
        }
        this.$emit('select-cam', null)
        this.selectSite = -1
        this.selectId = -1
      }
    },
    async onClickSaveLabel(eventId) {
      try {
        await alert.updateEventType({ log_id: this.selectId, event_type_id: eventId })
        this.setRecentLogListInWorkspace()
        this.currentLog = { ...this.currentLog, event_type_id: eventId }
      } catch (e) {
        this.$log.error(e)
      }
    },
    displayLogTime(log) {
      const cam = this.camMap[log.camera_id]
      if (cam) {
        const date = Util.getOffsetDate(log.created_at, log.offset)
        return `[${date}] ${cam.name}`
      } else {
        return log.log
      }
    },
    // onScroll(e) {
    //   const bottomScrollY = document.body.scrollHeight - window.pageYOffset - window.innerHeight
    //   const footerHeight = 252
    //   if (bottomScrollY < footerHeight) {
    //     this.asideMarginBottom = `margin-bottom:${footerHeight - bottomScrollY}px`
    //   } else {
    //     this.asideMarginBottom = 'margin-bottom:0px'
    //   }
    // },
    async refreshLogListv2() {
      const startDate = moment(this.datePeriod.startDate, 'YYYY-MM-DD').startOf('day').format()
      const endDate = moment(this.datePeriod.endDate, 'YYYY-MM-DD').endOf('day').format()
      await this.getLogListv2({ startDate, endDate, tabIndex: this.tabIndex, workerIds: this.workerIdsList })
      const routePathLength = this.$route.path.split('/').length
      if (this.logListv2 && this.logListv2.length && routePathLength === 3) {
        this.selectId = this.logListv2[0].id || this.logListv2[0].log_id
        this.onClickLog(this.logListv2[0])
      } else this.$emit('select-log', null)
    },
  },
  async created() {
    const updateToastDefaultOptions = {
      hideProgressBar: true,
      icon: false,
      maxToasts: 1,
      position: POSITION.TOP_RIGHT,
      timeout: 7000,
    }
    Vue.$toast.updateDefaults(updateToastDefaultOptions)
    await this.refreshLogListv2()
  },
}
</script>

<style lang="scss" scoped>
.background {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 485px;
  height: calc(100% - 72px);
  right: 0px;
  z-index: 1;

  &.worker {
    height: 100%;
  }
}

.content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 74px;
  padding-bottom: 25px;

  &.worker {
    margin-top: 105px;
  }
}

.text-map {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  color: var(--v-textDefault);
  margin-top: 15px;

  .text-map-direction {
    font-weight: 600;
    font-size: 15px;
    color: #ff5a5a;
    margin-right: 5px;
    user-select: text;
  }
}

.log-accordion-header {
  display: flex;
  align-items: center;
  margin: 15px 16px 0px 20px;
  border-radius: 15px;
  height: 67px;
  width: 435px;
  flex: 0 0 67px;
  cursor: pointer;
  background-color: var(--v-backgroundAsideHeader-base);

  &.active {
    background-color: #3d51fb;
  }

  &.disabled {
    background-color: var(--v-backgroundDisabled);
  }

  &.worker:last-child {
    margin-bottom: 56px;
  }
}

.img-recent {
  margin-left: 30px;
}

.img-arrow {
  margin-right: 30px;
}

.text-log-header {
  font-weight: 500;
  font-size: 20px;
  color: #3d51fb;
  user-select: none;

  &.active {
    color: #ffffff;
  }
}

.dropdown-wrapper {
  padding: 0px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  div {
    width: 272px;
  }
  .reset-calendar {
    color: #3d51fb;
    text-decoration: underline;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
}

.log-list-loading {
  height: 60px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.log-item-empty {
  height: 80px;
  width: 435px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 15px;
  margin: 15px 30px 0px 20px;
  font-weight: 500;
  font-size: 16px;
  color: #afb6c3;
}
</style>
