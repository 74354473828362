<template>
  <v-dialog v-model="value" max-width="443px" @keydown.esc="hide" persistent>
    <v-card style="border-radius: 30px; background-color: var(--v-backgroundAsideHeaderRecent-base)">
      <v-card-text class="pa-0">
        <div class="goto-label-dialog-title">Go to</div>
        <div class="goto-label-dialog-close" @click="hide">
          <img v-if="$vuetify.theme.dark" src="@/assets/Exit_Icon.svg" width="15px" />
          <img v-else src="@/assets/Exit_Icon_Black.svg" width="15px" />
        </div>
        <div class="goto-label-dialog-content">
          <div @click="onClickGoto(0)" class="goto-label-container" style="margin-bottom: 15px">
            <img src="@/assets/Public_Blue_Icon.svg" class="icon" />
            <div class="text-label">Camera Public Page</div>
            <img v-if="$vuetify.theme.dark" src="@/assets/Move_Icon.svg" class="icon-arrow" />
            <img v-else src="@/assets/Move_Black_Icon.svg" class="icon-arrow" />
          </div>
          <div @click="onClickGoto(1)" class="goto-label-container" style="margin-bottom: 15px">
            <img src="@/assets/Link_Blue_Icon.svg" class="icon" />
            <div class="text-label">Camera Access Link 1</div>
            <img v-if="$vuetify.theme.dark" src="@/assets/Move_Icon.svg" class="icon-arrow" />
            <img v-else src="@/assets/Move_Black_Icon.svg" class="icon-arrow" />
          </div>
          <div @click="onClickGoto(2)" class="goto-label-container">
            <img src="@/assets/Link_Blue_Icon.svg" class="icon" />
            <div class="text-label">Camera Access Link 2</div>
            <img v-if="$vuetify.theme.dark" src="@/assets/Move_Icon.svg" class="icon-arrow" />
            <img v-else src="@/assets/Move_Black_Icon.svg" class="icon-arrow" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import util from '@/util'

export default {
  props: {
    value: Boolean,
    urls: {
      type: Array,
      default: [],
    },
  },
  methods: {
    onClickGoto(index) {
      this.$log.debug('GotoDialog#onClickGoto', index)
      if (this.urls[index]) {
        util.openUrl(this.urls[index])
        this.hide()
      }
    },
    hide() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.goto-label-dialog-title {
  padding: 30px 40px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--v-textAside);
}

.goto-label-dialog-close {
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
}

.goto-label-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  padding: 40px 0;
  color: var(--v-textDefault);

  .icon {
    margin-left: 22px;
    margin-right: 12px;
  }

  .text-label {
    flex-grow: 1;
  }

  .icon-arrow {
    margin-right: 20px;
  }
}
.goto-label-container {
  display: flex;
  align-items: center;
  width: 363px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  background: var(--v-backgroundLinkGotoBox-base);
}
</style>
