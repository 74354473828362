<template>
  <section class="calendar-container">
    <div class="calendar-title">Date</div>
    <div class="calendar-date">{{ datePeriodText }}</div>
    <div class="calendar-image" @click="openCalendar"><img src="@/assets/Icon material-date-range.svg" /></div>
    <Calendar
      v-if="calendarOpen"
      :min-date="calendarMinDate"
      v-click-outside="onClickCalendarOutside"
      @update="onUpdateDate"
      class="calendar"
    ></Calendar>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import Calendar from '@common/Calendar'
import Constants from '@/constants'

export default {
  components: { Calendar },
  data() {
    return {
      calendarOpen: false,
    }
  },

  computed: {
    ...mapState({
      camMap: (state) => state.camMap,
      historyStart: (state) => state.user.history_start,
      userRole: (state) => state.user.user_role,
    }),
    datePeriodText() {
      return this.$store.getters['superadmin/datePeriodText']
    },
    calendarMinDate() {
      const isSuperadmin = this.userRole.includes(Constants.ROLE_SUPER)
      if (isSuperadmin) return moment().startOf('day').subtract(180, 'days')
      else return moment().startOf('day').subtract(60, 'days')
    },
  },
  methods: {
    onUpdateDate(date) {
      this.$store.dispatch('superadmin/setDatePeriodAction', date)
      this.calendarOpen = false
      this.$emit('on-update-aside-log-calendar')
    },
    openCalendar() {
      this.calendarOpen = !this.calendarOpen
    },
    onClickCalendarOutside() {
      this.calendarOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar-container {
  display: flex;
  margin: 15px 30px 0 20px;
}
.calendar-title {
  color: var(--v-textDefault-white);
  font-weight: 500;
  font-size: 20px;
}
.calendar-date {
  width: 320px;
  height: 40px;
  background-color: var(--v-backgroundDate-base);
  border-radius: 10px;
  padding: 9px 20px;
  margin-left: 15px;
  margin-right: 10px;
  color: var(--f-text-black);
}

.calendar-image {
  width: 40px;
  height: 40px;
  background-color: #3d51fb;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}
.calendar {
  position: absolute;
  top: 13%;
  left: -345px;
}
</style>
