<template>
  <div @click="$emit('click')" class="d-flex align-center" style="z-index: 1; cursor: pointer; font-weight: 500; font-size: 16px">
    <img v-if="$vuetify.theme.dark && !disabled" src="@/assets/Group 330_dark.svg" style="margin-right: 7px" />
    <img v-else-if="!disabled" src="@/assets/Group 330_light.svg" style="margin-right: 7px" />
    <img v-else src="@/assets/Copylink_Grey_Icon.svg" style="margin-right: 7px" />
    <span v-if="!disabled" style="color: var(--v-textAside)">Share</span>
    <span v-else style="color: #00000042">Share</span>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped></style>
