<template>
  <div @click="onClickPaly()" :class="{ 'butotn-play-circle': true, disabled: disabled }">
    <!-- play -->
    <div v-if="isPlay" class="icon-container">
      <img v-if="!disabled" src="@/assets/play.svg" style="margin-left: 3px" />
      <img v-else src="@/assets/play_disabled.svg" style="margin-left: 3px" />
    </div>
    <!-- resume -->
    <div v-if="isResume" class="icon-container">
      <img src="@/assets/play.svg" style="margin-left: 3px" />
    </div>
    <!-- playback -->
    <div v-if="isPlayback" class="icon-container">
      <img src="@/assets/playback.svg" />
    </div>
    <!-- pause -->
    <div v-if="isPause" class="icon-container">
      <img src="@/assets/pause.svg" />
    </div>
  </div>
</template>

<script>
import ButtonPlay from './ButtonPlay'
export default {
  extends: ButtonPlay,
}
</script>

<style lang="scss" scoped>
.butotn-play-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  width: 34px;
  height: 34px;
  background-color: #3d51fb;
  border-radius: 34px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffffde;

  &.disabled {
    background-color: #cbcbcb;
    color: #00000042;
  }
}

.icon-container {
  display: flex;
  align-items: center;
}
</style>
